<template>
  <div class="login-page">
    <div class="login-box">
      <div class="login-header">
        Vielott管理系统
      </div>
      <el-form :model="loginForm" :rules="rules" ref="loginForm" label-width="0">
        <el-form-item prop="username">
          <el-input
            v-model="loginForm.username"
            placeholder="请输入用户名"
            prefix-icon="el-icon-user"
          ></el-input>
        </el-form-item>
        <el-form-item prop="pwd">
          <el-input
            v-model="loginForm.pwd"
            placeholder="请输入密码"
            prefix-icon="el-icon-lock"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item  class="login-button" >
          <el-button type="primary" block @click="handleLogin">登录</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loginForm: {
        username: '',
        pwd: '',
      },
      rules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
        ],
        pwd: [
          { required: true, message: '请输入密码', trigger: 'blur' },
        ],
      },
    };
  },
  methods: {
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.$store.dispatch("auth/login", this.loginForm);
        } else {
          console.log('表单验证失败');
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
.login-page {
  display: flex;
  justify-content: center;
  align-items: flex-start; 
  height: 100vh;
  padding-top: 50px;

}

.login-box {
  width: 400px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden; /* 确保圆角效果也作用于标题 */
}

.login-header {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  background-color: #2C3E50;
  padding: 15px 0;
  width: 100%; /* 标题宽度填充整个登录框 */
}

.el-form {
  padding: 20px; /* 确保表单内容不紧贴标题 */
}

.el-form-item {
  margin-bottom: 20px;
}

.login-button {
text-align: right; /* 让登录按钮靠右 */
}
</style>
